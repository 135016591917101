import React from "react"

import { PageLayout } from "@components/layouts"
import Seo from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import ReactPlayer from 'react-player'

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

import { Helmet } from 'react-helmet';

const SchemaOrg = () => {
	const schema = [{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "The Websuasion Group LLC",
  "url": "https://websuasion.com/",
  "logo": "https://websuasion.com/images/websuasion-full-logo.png",
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+1-404-418-8909",
    "contactType": "Customer Service",
    "areaServed": "US",
    "availableLanguage": "English",
    "contactOption": "TollFree",
    "email": "sales@websuasion.com"
  },
  "sameAs": [
    "https://www.linkedin.com/company/websuasion",
    "https://www.youtube.com/@websuasion",
    "https://www.instagram.com/websuasion/",
    "https://www.threads.net/@websuasion",
    "https://twitter.com/websuasion",
    "https://www.tiktok.com/@leadarchitect",
    "https://www.facebook.com/websuasion"
  ],
  "description": "Websuasion is a fractional, white-label marketing agency offering data-driven strategies, advanced technologies, and a team of experienced specialists to deliver comprehensive digital marketing services.",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "PO BOX 142395",
    "addressLocality": "Fayetteville",
    "addressRegion": "GA",
    "postalCode": "30214-9998",
    "addressCountry": "US"
  },
  "founder": {
    "@type": "Person",
    "name": "J. Ryan Williams"
  },
  "employee": [
    {
      "@type": "Person",
      "name": "J. Ryan Williams",
      "jobTitle": "Fractional Chief Marketing Officer",
      "worksFor": {
        "@type": "Organization",
        "name": "The Websuasion Group LLC"
      }
    }
  ],
  "makesOffer": [
    {
      "@type": "Offer",
      "itemOffered": {
        "@type": "Service",
        "name": "Digital Marketing Services",
        "serviceType": "Digital Marketing Services",
        "provider": {
          "@type": "Organization",
          "name": "The Websuasion Group LLC"
        },
        "areaServed": [
          {
            "@type": "Place",
            "name": "Newnan",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Newnan",
              "addressRegion": "GA",
              "addressCountry": "US"
            }
          },
        ]
      }
    },
  ]
},
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "What is the cost of digital marketing?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Digital marketing costs vary widely based on services and providers. For SEO: Price range of $1,000-$10,000 monthly. Our full-service SEO begins at $3,000/month, including weekly content creation, page updates, and minimal link building. This minimum threshold ensures predictable outcomes. For smaller budgets, consider a DIY approach with our consultative support. For Website Design: Costs span from $700 to $10,000. Our ReactJS/GatsbyJS designs typically cost $2,500, while WordPress designs start at $3,500. The design covers design and functionality, excluding content creation. For Pay-Per-Click (PPC): Self-managed campaigns start at $500/month in ad spend. Agency-managed campaigns usually require $3,000+/month. Our service requires a $3,000/month minimum ad spend (paid directly to the platform) plus a $2,000 monthly management fee. For Social Media Marketing: $1,000-$10,000 monthly, depending on content type and volume. Our standard $1,600/month package includes a monthly half-day shoot, resulting in various video and photo content for multiple platforms."
      }
    },
    {
      "@type": "Question",
      "name": "What is a good budget for digital marketing?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A good rule of thumb is to allocate 5% of monthly revenue to marketing. However, there's a minimum threshold for effectiveness. Consider a DIY approach with our strategic guidance if you're below this. We can provide research, frameworks, and periodic reviews to ensure you're progressing towards your goals."
      }
    },
    {
      "@type": "Question",
      "name": "What are services in digital marketing?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Digital marketing services typically include: Content marketing and SEO, Strategic social media management, and Pay-per-click advertising. These often encompass copywriting, video and audio production, web development, graphic design, email marketing, webinars, and integration with marketing technology."
      }
    },
    {
      "@type": "Question",
      "name": "Which service is best for digital marketing?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Content marketing offers the best long-term value. When based on a comprehensive Keyword Research and Positioning Plan, it yields lasting search results, social media impact, and tested assets for PPC campaigns. While it takes time to establish, the results are enduring."
      }
    },
    {
      "@type": "Question",
      "name": "What exactly does a digital marketing agency do?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "With over 27 years of experience, our approach includes: 1. Conducting in-depth research and developing tailored strategies. 2. Creating content that drives SEO results and social media engagement. 3. Leveraging social media successes to inform paid advertising campaigns. 4. Continuously measuring, analyzing, and optimizing based on results. We focus on maximizing your budget by concentrating our efforts where they'll provide the most significant value for your business."
      }
    },
    {
      "@type": "Question",
      "name": "Which digital marketing is best for beginners?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "For beginners armed with our Keyword Research and Positioning Plan and some training, we recommend focusing on: 1. Creating SEO-optimized website content that ranks in search results. 2. Developing engaging social media content that builds brand impact. 3. Refining messaging to communicate with ideal clients effectively. As your business grows and success increases, you can gradually outsource these tasks to a fractional marketing agency like ours, allowing you to focus more on core business activities."
      }
    }
  ]
},
{
  "@context": "https://schema.org",
  "@type": "Article",
  "headline": "Digital Marketing Services in Newnan, GA",
  "author": {
    "@type": "Person",
    "name": "J. Ryan Williams"
  },
  "datePublished": "2024-09-09",
	"dateModified" : "2024-09-09",
  "publisher": {
    "@type": "Organization",
    "name": "The Websuasion Group LLC", 
    "url": "https://websuasion.com",
    "logo": {
      "@type": "ImageObject",
      "url": "https://websuasion.com/images/websuasion-full-logo.png"
    }
  },
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://websuasion.com/newnan-georgia/digital-marketing-services/"
  },
  "image": "https://websuasion.com/images/newnan-ga-digital-marketing-services.webp",
  "articleSection": "Digital Marketing Services in Newnan, GA",
  "description": "Get expert digital marketing services in Newnan, GA. Websuasion offers customized strategies and frameworks from DIY plans to full-service solutions.",
  "keywords": ["Search Engine Optimization", "Keyword Research", "Content Strategy Development", "Content Plan Development", "Web Design", "SEO", "Local SEO", "Technical SEO", "Link Building", "Social Media Marketing", "Pay Per Click Ads"],
  "hasPart": [
    {
      "@type": "VideoObject",
      "name": "Atlanta's Digital Marketing Agency - Fractional and White-Label - Websuasion",
      "description": "Websuasion is a digital marketing agency providing our customers Data, Tech, and Team. Research drives our strategy. We use existing technology and develop custom solutions. And our Atlanta-based professional team delivers experience and creative intuition.",
      "thumbnailUrl": "https://i.ytimg.com/vi/y8cuhnDNyWY/maxresdefault.jpg",
      "uploadDate": "2024-05-17T08:26:00-07:00",
      "duration": "PT9M22S",
      "contentUrl": "https://www.youtube.com/watch?v=y8cuhnDNyWY",
      "embedUrl": "https://www.youtube.com/embed/y8cuhnDNyWY"
    },
    {
      "@type": "VideoObject",
      "name": "Contacting Websuasion for an Introductory Meeting",
      "description": "At Websuasion, our motto is: Data. Tech. Team. Do the research. Harness the technology. But ultimately, success comes down to human decisions. We hope to get to know you and your business soon.",
      "thumbnailUrl": "https://i.ytimg.com/vi/-YTR647HTBM/maxresdefault.jpg",
      "uploadDate": "2024-05-16T10:07:01-07:00",
      "duration": "PT1M9S",
      "contentUrl": "https://www.youtube.com/watch?v=-YTR647HTBM",
      "embedUrl": "https://www.youtube.com/embed/-YTR647HTBM"
    }
  ]
}];
	
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};

const IndexPage = () => {
  return (
    <PageLayout>
			<SchemaOrg />
      <Seo
        title="Digital Marketing Services in Newnan, GA | Websuasion"
        ogDescription="Get expert digital marketing services in Newnan, GA. Websuasion offers customized strategies and frameworks from DIY plans to full-service solutions."
        image="/images/newnan-ga-digital-marketing-services.webp"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Digital Marketing Services in Newnan, GA</h1>
					
						<p>
							Are you in search of exceptional digital marketing services in Newnan, GA? Websuasion stands out with its <a href="/content-marketing-strategy/data-driven-seo/">data-driven content strategy</a> and flexible partnerships with local businesses. Our unique approach begins with a comprehensive Keyword Research and Positioning Plan, setting the stage for your digital success.
						</p>

		        <FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
          </div>
        }
        textRight={
		      <div className='player-wrapper'>
		        <ReactPlayer
		          className='react-player'
		          url='https://www.youtube.com/watch?v=y8cuhnDNyWY'
		          width='100%'
		          height='100%'
		        />
		      </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
							<h2>The Keystone of Our Strategy</h2>
							<h4>In-Depth Keyword Research and Positioning</h4>
							<p>
								Our meticulously crafted Keyword Research and Positioning Plan is at the heart of our methodology. Our plans aren't a run-of-the-mill keyword list; it's a data-rich blueprint that analyzes hundreds of keyword phrases, carefully mapping them to your specific business solutions.
							</p>
							<p>
								This plan is crucial - without it, we'd be relying on guesswork, a common pitfall for many businesses and even some agencies. Your customized plan becomes the foundation for all your digital marketing initiatives, enabling informed decision-making about your brand messaging and providing a solid basis for measuring progress.
							</p>
							<p>
								Our process includes a thorough competitor analysis for each keyword. These strategically developing content prompts allow you to create material that dominates search results and connects with your target audience. This approach eliminates the uncertainty that often plagues marketing efforts, giving you a clear path to quantifiable success.
							</p>
          </div>
        }
      />
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
	            <img src="/images/newnan-ga-digital-marketing-services.webp" className="rounded-image" alt="Digital Marketing Services in Newnan, GA - Websuasion" />
	          </div>
	        }
	        textRight={
			      <div>
							<h2>Embracing Newnan: A Gem in Georgia</h2>
							<p>
								Affectionately known as the "City of Homes," Newnan is a picturesque Southern community in Coweta County, Georgia, just 40 miles southwest of Atlanta. Established in 1828, Newnan was named in honor of General Daniel Newnan, a North Carolina Revolutionary War hero. The town has evolved into a thriving hub that expertly balances its storied past with contemporary conveniences. The heart of Newnan, its historic downtown, showcases well-preserved 19th-century architecture surrounding the courthouse square, creating a vibrant tapestry of local boutiques, eateries, and enterprises.
							</p>
							<p>
								Home to over 40,000 residents, Newnan has seen remarkable growth in recent years without sacrificing its small-town allure. Its prime location along Interstate 85 offers convenient access to Atlanta and Hartsfield-Jackson International Airport, making it an ideal destination for businesses and families seeking a high-quality lifestyle with easy reach to metropolitan amenities.
							</p>
			      </div>
	        }
	      />
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Web Design with Purpose: Every Page Counts</h2>
							<p>
								At Websuasion, we operate on the principle that "Every Page Of Your Site Has A Purpose." This core belief shapes our <a href="/development/software-development-company/">web development process</a>. Unlike many in the industry who treat keyword research as an afterthought, we integrate it from the start, informing critical decisions about site navigation and page structure.
							</p>
							<p>
								Each webpage should aim to produce a search result or track and convert traffic from social media or ad campaigns. This strategy ensures that we position every page to attract your ideal customers, with even branding elements like community involvement and company values strategically woven into search-optimized pages.
							</p>
						
			        <FormWrapper
			          modal
			          buttonProps={{
			            content: "Schedule An Introductory Call",
			            size: "tiny",
			            icon: "calendar alternate outline",
			          }}
			        >
			          <RequestMoreInfo />
			        </FormWrapper>
						
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
	            <img src="/images/digital-marketing-services-near-me.webp" className="rounded-image" alt="Digital Marketing for Newnan Businesses - Websuasion" />
	          </div>
	        }
	        textRight={
			      <div>
							<h2>Tailored Solutions for Every Business</h2>

							<p>
								We've crafted our digital marketing services to accommodate three main client types:
							</p>
							<ol>
								<li>
									<strong>Businesses with Varied Past Results:</strong> We understand that SEO and social media efforts often take time to bear fruit, so we advocate for persistent efforts to achieve the best outcomes.
								</li>
								<li>
									<strong>Hands-On Business Owners:</strong> Recognizing that many startups have more time than financial resources, we provide a structured framework and guidance for content creation, allowing you to leverage your unique industry expertise.
								</li>
								<li>
									<strong>Growing Mid-Market Companies:</strong> Our Fractional Marketing Services act as your cost-effective marketing department, efficiently extracting key organizational insights through Zoom interviews and offering full-service content creation options.
								</li>
							</ol>
								
							<h2>Streamlined Content Marketing Framework</h2>

							<p>
								Our content marketing framework simplifies your journey to online success. Leveraging your Keyword Research and Positioning Plan, we deliver comprehensive content outlines with recommended URL structures, title tags, meta descriptions, internal linking strategies, alt image tags, and schema data. We ensure your content resonates with your target audience while producing search engine results.
							</p>
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Comprehensive Digital Marketing Suite</h2>

							<p>
								Our services go well beyond basic SEO. Our keyword plan fuels a 52-week content strategy encompassing targeted articles, social media content, and PPC assets. Our offerings include:
							</p>
								
							<ul>
								<li>Tailored Social Media Strategies</li>
								<li>Full-Service PPC Advertising Management</li>
								<li>Professional Video Production for Various Platforms</li>
								<li>Automated Webinar Lead Generation</li>
								<li>Cost-Effective Local SEO Solutions</li>
								<li>Custom Web Portal Development and Integration</li>
							</ul>

							<h2>The Websuasion Difference</h2>

							<p>
								Our unwavering commitment to data-driven strategies and adaptable, client-focused approach set us apart. Whether you need full-scale management or prefer a guided DIY approach, we equip you with the tools, expertise, and support essential for success.
							</p>
								
							<p>
								Our strategic 52-week content plan facilitates long-term consistency, allowing you to respond to our content prompts or have our team manage the entire process.
							</p>
								
							<p>
								For businesses seeking top-tier digital marketing services in Newnan, GA, Websuasion is your go-to partner for implementing effective digital marketing strategies. Combining exhaustive research, strategic planning, and flexible services, we position your business ahead of the competition.
							</p>
								
			        <FormWrapper
			          modal
			          buttonProps={{
			            content: "Schedule An Introductory Call",
			            size: "tiny",
			            icon: "calendar alternate outline",
			          }}
			        >
			          <RequestMoreInfo />
			        </FormWrapper>
						
	          </div>
	        }
	      />
		</PageWrapper>
		<PageWrapper color="white">
      <SingleColumnBlock
        textPadded
        text={
          <div>
						<h2>Frequently Asked Questions About Digital Marketing Services</h2>

					  <details>
					    <summary>What is the cost of digital marketing?</summary>
					    <p>
								Digital marketing costs vary widely based on services and providers:
							</p>
							<ul>
								<li>
									<strong>SEO</strong>: Price range of $1,000-$10,000 monthly. Our full-service SEO begins at $3,000/month, including weekly content creation, page updates, and minimal link building. This minimum threshold ensures predictable outcomes. For smaller budgets, consider a DIY approach with our consultative support.
								</li>
								<li>
									<strong>Website Design</strong>: Costs span from $700 to $10,000. Our ReactJS/GatsbyJS designs typically cost $2,500, while WordPress designs start at $3,500. The design covers design and functionality, excluding content creation.
								</li>
								<li>
									<strong>Pay-Per-Click (PPC)</strong>: Self-managed campaigns start at $500/month in ad spend. Agency-managed campaigns usually require $3,000+/month. Our service requires a $3,000/month minimum ad spend (paid directly to the platform) plus a $2,000 monthly management fee.
								</li>
								<li>
									<strong>Social Media Marketing</strong>: $1,000-$10,000 monthly, depending on content type and volume. Our standard $1,600/month package includes a monthly half-day shoot, resulting in various video and photo content for multiple platforms.
								</li>
							</ul>
					  </details>
  
					  <details>
					    <summary>What is a good budget for digital marketing?</summary>
					    <p>
								A good rule of thumb is to allocate 5% of monthly revenue to marketing. However, there's a minimum threshold for effectiveness. Consider a DIY approach with our strategic guidance if you're below this. We can provide research, frameworks, and periodic reviews to ensure you're progressing towards your goals.
							</p>
					  </details>
					
					  <details>
					    <summary>What are services in digital marketing?</summary>
					    <p>
								Digital marketing services typically include:
							</p>
							<ul>
								<li>Content marketing and SEO</li>
								<li>Strategic social media management</li>
								<li>Pay-per-click advertising</li>
							</ul>
							<p>
								These often encompass copywriting, video and audio production, web development, graphic design, email marketing, <a href="/atlanta-video-production/webinar-production/">automated webinars</a>, and integration with marketing technology.
							</p>
					  </details>
  
					  <details>
					    <summary>Which service is best for digital marketing?</summary>
					    <p>
								Content marketing offers the best long-term value. When based on a comprehensive Keyword Research and Positioning Plan, it yields lasting search results, social media impact, and tested assets for PPC campaigns. While it takes time to establish, the results are enduring.
							</p>
					  </details>
					
					  <details>
					    <summary>What exactly does a digital marketing agency do?</summary>
					    <p>
								With over 27 years of experience, our approach includes:
							</p>
							<ol>
								<li>Conducting in-depth research and developing tailored strategies</li>
								<li>Creating content that drives SEO results and social media engagement</li>
								<li>Leveraging social media successes to inform paid advertising campaigns</li>
								<li>Continuously measuring, analyzing, and optimizing based on results</li>
							</ol>
							<p>
								We focus on maximizing your budget by concentrating our efforts where they'll provide the most significant value for your business.
							</p>
					  </details>
  
					  <details>
					    <summary>Which digital marketing is best for beginners?</summary>
					    <p>
								For beginners armed with our Keyword Research and Positioning Plan and some training, we recommend focusing on:
							</p>
							<ol>
								<li>Creating SEO-optimized website content that ranks in search results</li>
								<li>Developing engaging social media content that builds brand impact</li>
								<li>Refining messaging to communicate with ideal clients effectively</li>
							</ol>
							<p>
								As your business grows and success increases, you can gradually outsource these tasks to a <a href="/fractional-marketing-team/">fractional marketing agency</a> like ours, allowing you to focus more on core business activities.
							</p>
					  </details>
					
						<p>
							Ready to drive leads to your Newnan business? Contact Websuasion today for a free consultation and discover how getting started with our Keyword Research and Positioning Plans can drive more targeted traffic to your website.
						</p>
          </div>
        }
      />
		</PageWrapper>
    <PageWrapper color="white">				
			<TextBlock
        textPadded
        textLeft={
          <div>
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/watch?v=-YTR647HTBM'
			          width='100%'
			          height='100%'
			        />
						</div>
          </div>
        }
        textRight={
          <div>
            <h4>Let's Talk!</h4>
            <p>Websuasion combines data analytics, cutting-edge tech, and expert teams. "Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <RequestMoreInfo />
          </div>
        }
      />
		</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage